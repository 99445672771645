import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { configureStore } from "./state/store";
import router from "./router";

import "bootstrap/scss/bootstrap.scss";
import "font-awesome/scss/font-awesome.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-datetime/css/react-datetime.css";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const store = configureStore({});

declare global {
  interface Window {
    readonly FB: {
      login: any;
    };
    googleCallback: (googleUser: any) => void;
    readonly auth2: {
      attachClickHandler: any;
    };
  }
}

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
