import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  datetime: string;
  position?: string;
  content: string;
};

class Message extends PureComponent<Props> {
  static defaultProps = {
    position: "left",
  };

  render() {
    const { datetime, content, position } = this.props;

    return (
      <div
        className={classNames("message", {
          "message--right": position === "right",
          "message--left": position === "left",
        })}
      >
        <div
          className="message__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <small className="message__date">{datetime}</small>
      </div>
    );
  }
}

export default Message;
