import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import OilRequestPagination from "./sub/OilRequestPagination";
import OilRequestFilters from "./sub/OilRequestFilters";
import OilRequestList from "./sub/OilRequestList";
import Loader from "../../common/Loader";
import { OilRequestOrder, UserRole } from "../../utils/config";
import { getOilRequests } from "../../services/turgoil-api";
import { RouteComponentProps, withRouter } from "../../common/withRouter";

export const Tabs = Object.freeze({
  AllOilRequests: "AllOilRequests",
  RequesterOilRequests: "RequesterOilRequests",
  BidderOilRequests: "BidderOilRequests",
});

const LIMIT = 25;

/**
 * @param {number} totalOilRequests
 * @param {number} limit
 * @return {number}
 */
function getMaximumPage(totalOilRequests, limit) {
  return Math.ceil(totalOilRequests / limit) || 1;
}

type MapStateToProps = {
  user: any;
};

type MapDispatchToProps = {};

type Props = MapStateToProps & MapDispatchToProps & RouteComponentProps;

const noop = () => undefined;

class OilRequestsPage extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      oilRequests: [],
      orderBy: OilRequestOrder.OilRequestRegisteredDesc,
      page: 1,
      totalOilRequests: 0,
    };
  }

  componentDidMount() {
    // TODO!!!
    // this.fetchOilRequests(this.props.router.query);
    this.fetchOilRequests({});
  }

  /**
   * @param {number} page
   */
  handlePageChange = (page) => {
    const { totalOilRequests, orderBy } = this.state;
    const maximumPage = getMaximumPage(totalOilRequests, LIMIT);

    const pageInt = parseInt(page, 10);
    if (!Number.isInteger(pageInt)) {
      return;
    }

    if (pageInt < 1 || pageInt > maximumPage) {
      return;
    }

    // TODO !!!
    // const query = { ...this.props.router.query };
    const query: any = {};
    query.page = pageInt;
    query.orderBy = orderBy;
    query.offset = (query.page - 1) * LIMIT;

    this.fetchOilRequests(query);
  };

  handleOrderByChange = (orderBy) => {
    // TODO !!! const query = { ...this.props.router.query };
    const query: any = {};
    query.orderBy = orderBy;

    this.fetchOilRequests(query);
  };

  /**
   * @param {Object} query
   */
  fetchOilRequests = (query) => {
    const { orderBy } = this.state;
    this.setState({ loading: true });

    const queryParameters = {
      offset: (query.page ? query.page - 1 : 0) * LIMIT,
      limit: LIMIT,
      orderBy,
      ...query,
    };

    // We remove limit and offset because it's hardcoded
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { limit, offset, ...restQueryParams } = queryParameters;

    getOilRequests(queryParameters)
      .then((res) => {
        this.setState({
          loading: false,
          oilRequests: res.oilRequests,
          orderBy:
            queryParameters.orderBy || OilRequestOrder.OilRequestRegisteredDesc,
          page: parseInt(queryParameters.page, 10) || 1,
          totalOilRequests: res.total,
        });

        // TODO !!!
        // Router.replace({
        // 	pathname: '/oil-requests',
        // 	query: restQueryParams,
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { loading, orderBy, page, oilRequests, totalOilRequests } =
      this.state;
    const { user } = this.props;

    return (
      <>
        <Helmet>
          <title>Turgoil - Hanked</title>
        </Helmet>

        <h3 className="page-content__title">
          {user.role === UserRole.Bidder ? 'Hanked' : 'Minu hanked'}
        </h3>

        <OilRequestFilters
          onSearch={this.fetchOilRequests}
          user={this.props.user}
        />

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="request-page">
              <OilRequestPagination
                onOrderByChange={this.handleOrderByChange}
                onPageChange={this.handlePageChange}
                orderBy={orderBy}
                page={page}
                totalOilRequests={totalOilRequests}
                limit={LIMIT}
              />
              <OilRequestList
                oilRequests={oilRequests}
                onReload={this.fetchOilRequests}
              />
              <OilRequestPagination
                onOrderByChange={this.handleOrderByChange}
                onPageChange={this.handlePageChange}
                orderBy={orderBy}
                page={page}
                totalOilRequests={totalOilRequests}
                limit={LIMIT}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state: any): MapStateToProps {
  return {
    user: state.userReducer.user,
  };
}

export default connect(mapStateToProps)(withRouter(OilRequestsPage));
