import React, { PureComponent } from "react";
import Big from "big.js";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";

import { getOilTypeLongName } from "../../OilRequestsPage/helpers";
import { numberWithSpaces } from "../../../utils/format";
import * as toastActions from "../../../state/toasts";
import { ToastType } from "../../../common/Toasts";
import {
  createOilRequestBid,
  editOilRequestBid,
} from "../../../services/turgoil-api";

function calculateTotalPrice(oilBids, oils) {
  let total = new Big(0);

  oils.forEach((oil) => {
    const oilBid = oilBids.find((oBid) => oil.id === oBid.oilId);
    if (oilBid.price) {
      total = total.plus(new Big(oilBid.price || 0).times(oil.amount));
    }
  });

  return total.toFixed(2);
}

const Mode = {
  Edit: "Edit",
  View: "View",
};

function getMode(user, oilRequestBids) {
  const bid = oilRequestBids.find(
    (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
  );
  if (bid) {
    return Mode.Edit;
  }

  return Mode.View;
}

function getInitialState(user, oils, oilRequestBids) {
  if (user.role === "Bidder") {
    const bid = oilRequestBids.find(
      (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
    );
    if (bid) {
      return {
        price: bid.price,
        oilBids: bid.oilBids,
        comment: bid.comment,
        errors: {},
      };
    }
  }

  const oilBids = oils.map((oil) => ({
    price: "",
    oilId: oil.id,
  }));

  return {
    price: "0",
    comment: "",
    errors: {},
    oilBids,
  };
}

type Props = {
  user: any;
  oilRequest: any;
  oilRequestBids: any[];
  updateOilRequest: () => void;
  showToast: typeof toastActions.showToast;
};

type State = {
  price: string;
  comment: string;
  errors: Record<string, string>;
  oilBids: any[];
};

function countDecimals(number: string): number {
  return number.split(".")[1]?.length || 0;
}

export function getDisplayValueWithTax(num: string, precision?: number): string {
  const valueWithTax = new Big(num || 0).times(new Big("1.22"));
  return countDecimals(valueWithTax.toFixed()) > precision
    ? valueWithTax.toFixed(precision)
    : valueWithTax.toFixed();
}

class CreateOrEditBid extends PureComponent<Props, State> {
  private mode: string;

  constructor(props) {
    super(props);

    this.mode = getMode(props.user, props.oilRequestBids);

    this.state = getInitialState(
      props.user,
      props.oilRequest.oils,
      props.oilRequestBids,
    );
  }

  validate = () => {
    const { oilBids } = this.state;
    const errors = {};

    for (const bid of oilBids) {
      if (!bid.price) {
        errors[bid.oilId] = "See väli on kohustuslik.";
      }
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handleCreateBid = (e) => {
    const { oilRequest, oilRequestBids, showToast, user } = this.props;
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    if (this.mode !== Mode.Edit) {
      createOilRequestBid(oilRequest.id, this.state)
        .then(() => {
          this.props.updateOilRequest();
          showToast({
            title: "Päring õnnestus.",
            text: "Pakkumine edukalt loodud!",
            type: ToastType.Success,
          });
        })
        .catch((err) => {
          showToast({
            title: "Päring ebaõnnestus.",
            text: err.message,
            type: ToastType.Error,
          });
        });

      return;
    }

    const bidId = oilRequestBids.find(
      (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
    ).id;

    editOilRequestBid(oilRequest.id, bidId, this.state)
      .then(() => {
        this.props.updateOilRequest();
        showToast({
          title: "Päring õnnestus.",
          text: "Pakkumine edukalt muudetud!",
          type: ToastType.Success,
        });
      })
      .catch((err) => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: err.message,
          type: ToastType.Error,
        });
      });
  };

  getBidInputs = () => {
    const { oilRequest } = this.props;
    const { errors } = this.state;

    return oilRequest.oils.map((oil) => {
      const valueWithTax = getDisplayValueWithTax(this.getOilBidPrice(oil.id));

      return (
        <React.Fragment key={oil.oilType}>
          {/* <div className="col-sm-2" key={oil.oilType}> */}
          {/* 	<div className="form-group mb-2"> */}
          {/* 		<label>{getOilTypeName(oil.oilType)}</label> */}
          {/* 		<input */}
          {/* 			className="form-control" */}
          {/* 			type="number" */}
          {/* 			step={0.0001} */}
          {/* 			min={0} */}
          {/* 			name={oil.id} */}
          {/* 			value={this.getOilBidPrice(oil.id)} */}
          {/* 			onChange={this.handleOilBidPriceChange} */}
          {/* 		/> */}

          {/* 		{errors[oil.id] && <ErrorBox noArrow>{errors[oil.id]}</ErrorBox>} */}
          {/* 	</div> */}
          {/* </div> */}

          <div className="col-sm-6">
            <InputGroup className="mb-2">
              <InputGroup.Text>
                {getOilTypeLongName(oil.oilType)}
              </InputGroup.Text>
              <Form.Control
                type="number"
                min="0"
                onChange={this.handleOilBidPriceChange}
                name={oil.id}
                step={"0.1"}
                value={this.getOilBidPrice(oil.id)}
                isInvalid={!!errors[oil.id]}
              />
              <InputGroup.Text>€</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {errors[oil.id]}
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <div className="col-sm-6">
            <InputGroup className="mb-2">
              <InputGroup.Text>
                {getOilTypeLongName(oil.oilType)}
              </InputGroup.Text>
              <Form.Control
                type="number"
                onChange={this.handleOilBidPriceChange}
                name={oil.id}
                value={valueWithTax}
                disabled
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </div>
        </React.Fragment>
      );
    });
  };

  getOilBidPrice = (oilId) => {
    const { oilBids } = this.state;
    const oilBidRes = oilBids.find((oilBid) => oilBid.oilId === oilId);
    return oilBidRes.price;
  };

  handleOilBidPriceChange = (e) => {
    const { oilRequest } = this.props;
    const { oilBids } = this.state;

    const copyOilBids = oilBids.map((oilBid) => ({ ...oilBid }));
    const copyOilBid = copyOilBids.find(
      (oilBid) => oilBid.oilId === parseInt(e.target.name, 10),
    );
    copyOilBid.price = e.target.value;

    const totalPrice = calculateTotalPrice(copyOilBids, oilRequest.oils);

    this.setState({
      oilBids: copyOilBids,
      price: totalPrice,
    });
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  render() {
    const { oilBids, price } = this.state;
    const { oilRequest } = this.props;
    const { oils } = oilRequest;

    return (
      <section>
        <h5 className="oil-request-page__heading">
          {this.mode === Mode.Edit ? "Muuda pakkumist" : "Tee pakkumine"}
        </h5>
        {/* <Form.Text muted> */}
        {/* 	Sisesta vedelkütuse liitri hind koos käibemaksuga. */}
        {/* </Form.Text> */}
        <hr />

        <div className="row">
          <div className="col-sm-6 form-label d-none d-sm-block">
            Hind ilma käibemaksuta
          </div>
          <div className="col-sm-6 form-label d-none d-sm-block">
            Hind koos käibemaksuga
          </div>
        </div>
        <div className="row">{this.getBidInputs()}</div>

        <div className="row">
          <div className="col-sm-12">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Kommentaar
              </Form.Label>
              <textarea
                onChange={this.handleChange}
                name="comment"
                value={this.state.comment}
                className="form-control"
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Pakkumine ilma käibemaksuta
              </Form.Label>
              <Form.Control
                value={numberWithSpaces(new Big(price || 0).toFixed(2)) + " €"}
                plaintext
              />
            </Form.Group>
          </div>
          <div className="col-sm-6">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Pakkumine koos käibemaksuga
              </Form.Label>
              <Form.Control
                value={numberWithSpaces(getDisplayValueWithTax(price)) + " €"}
                plaintext
              />
            </Form.Group>
          </div>
        </div>
        <button
          className="btn btn-warning"
          type="submit"
          onClick={this.handleCreateBid}
        >
          {this.mode === Mode.Edit ? "Muuda" : "Tee pakkumine"}
        </button>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(null, mapDispatchToProps)(CreateOrEditBid);
