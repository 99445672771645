import React, { PureComponent } from "react";
import { Popover, Overlay, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Big from "big.js";

import * as date from "../../../utils/date";
import { numberWithSpaces } from "../../../utils/format";
import { UserRole } from "../../../utils/config";
import { getDisplayValueWithTax } from "./CreateOrEditBid";
import { getNameForOil } from "../../../common/Oils";

/**
 * Requester can see all the information only if request is in
 * the following states.
 */
const RequestStates = ["Decision", "Active", "Closed", "Transport"];

type Props = {
  requesterId: number;
  requestStateCode: string;
  bid: any;
  user: any;
  onOilRequestWinner: (e: any, b: any) => void;
  oilRequestMarkup?: string;
  oilRequestOils: { oilType: string, id: number }[];
};

type State = {
  showConfirm: boolean;
  markup: string;
  markupError: string;
};

class Bid extends PureComponent<Props, State> {
  private confirmButtonRef: React.RefObject<HTMLButtonElement> =
    React.createRef();

  private confirmButtonMobileRef: React.RefObject<HTMLButtonElement> =
    React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      markup: "",
      markupError: "",
    };
  }

  toggleConfirm = () => {
    this.setState((prevState) => ({
      showConfirm: !prevState.showConfirm,
    }));
  };

  hidePopover = () => {
    this.setState({ showConfirm: false });
  };

  handleOilRequestWinner = (e) => {
    const { onOilRequestWinner } = this.props;
    const { markup } = this.state;
    const bidId = e.target.getAttribute("data-value");
    if (markup && new Big(markup || 0).lt(0)) {
      this.setState({
        markupError: "Juurdehindlus peab olema positiivne väärtus",
      });
      return;
    }

    onOilRequestWinner(bidId, markup);
  };

  handleMarkupChange = (e) => {
    e.persist();

    this.setState({
      markup: e.target.value,
      markupError: "",
    });
  };

  private getBidTooltip = (bids: [{ price: number, oilId: number }]): React.ReactNode => {
    const { oilRequestOils } = this.props;
    return (bids || []).map((bid) => {
      const oilType = oilRequestOils.find((o) => o.id === bid.oilId).oilType;
      return (
        <>
          {getNameForOil(oilType)}: {bid.price}
          <br />
        </>
      );
    })
  }

  render() {
    const { bid, user, requesterId, requestStateCode } = this.props;
    const { oilRequestMarkup } = this.props;

    const REQUESTER =
      RequestStates.includes(requestStateCode) && requesterId === user.id;
    const REQUEST_STATUS_DECISION = requestStateCode === "Decision";

    const confirmPopover = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">
          Kas olete kindel, et hanke võitjaks kuulutatakse{" "}
          <span className="configrm__emphasize">{bid.companyName}</span>?
        </Popover.Header>
        <Popover.Body>
          <p>
            Pärast võitja valimist, läheb hange olekusse "Teostamine". Ning
            Teile saadetakse võitja kontaktandmed.
          </p>

          {[UserRole.Agent].includes(user.role) && (
            <Form.Group className="mb-2">
              <Form.Label className="mb-0 d-block">
                Juurdehindlus eurodes
              </Form.Label>
              <Form.Text muted>
                Täitke juhul kui olete vahendaja ning soovite, et arvel kajastuks
                suurem summa.
              </Form.Text>
              <Form.Control
                type="number"
                name="markup"
                placeholder=""
                onChange={this.handleMarkupChange}
                isInvalid={Boolean(this.state.markupError)}
                value={this.state.markup}
                step="1"
                min="0"
              />

              <Form.Control.Feedback type="invalid">
                {this.state.markupError}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {this.state.markup && (
            <>
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">Summa</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  plaintext
                  readOnly
                  value={
                    numberWithSpaces(
                      new Big(bid.price || 0)
                        .plus(this.state.markup || 0)
                        .toFixed(2),
                    ) + " €"
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">Summa käibemaksuga</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  plaintext
                  readOnly
                  value={
                    numberWithSpaces(getDisplayValueWithTax(
                      new Big(bid.price || 0)
                        .plus(this.state.markup || 0)
                      , 2)) + " €"
                  }
                />
              </Form.Group>
            </>
          )}

          <hr />

          <Button type="button" variant="light" onClick={this.hidePopover}>
            Loobu
          </Button>
          <Button
            type="button"
            variant="warning"
            className="float-end"
            onClick={this.handleOilRequestWinner}
            data-value={bid.id}
          >
            Jah
          </Button>
        </Popover.Body>
      </Popover>
    );

    return (
      <>
        <div className="oil-request-page__bid-description-mobile">
          Pakkuja:
          <div className="oil-request-page__mobile-show">
            {REQUESTER && REQUEST_STATUS_DECISION && (
              <>
                <Button
                  type="button"
                  variant="warning"
                  ref={this.confirmButtonRef}
                  onClick={this.toggleConfirm}
                >
                  Kuuluta võitjaks
                </Button>
                <Overlay
                  show={this.state.showConfirm}
                  onHide={this.hidePopover}
                  target={this.confirmButtonRef.current}
                >
                  {confirmPopover}
                </Overlay>
              </>
            )}
          </div>
        </div>
        <div style={{ paddingRight: "40px" }}>
          {bid.companyName ? `${bid.companyName}` : "Varjatud"}
        </div>
        <div className="oil-request-page__bid-description-mobile">Pakkumine:</div>
        <div>
          {bid.price && (
            bid.oilBids ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {this.getBidTooltip(bid.oilBids)}
                  </Tooltip>
                }
              >
                <span>
                  {numberWithSpaces(new Big(bid.price || 0).toFixed(2))} €
                </span>
              </OverlayTrigger>
            ) : <span>{numberWithSpaces(new Big(bid.price || 0).toFixed(2))} €</span>
          )}
          {!bid.price && "Varjatud"}
        </div>
        <div className="oil-request-page__bid-description-mobile">
          {!oilRequestMarkup && 'Pakkumine käibemaksuga:'}
        </div>
        <div>
          {!oilRequestMarkup && (
            <>
              {bid.price &&
                `${numberWithSpaces(getDisplayValueWithTax(new Big(bid.price || 0).toFixed(2), 2))} €`}
              {!bid.price && "Varjatud"}
            </>
          )}
        </div>
        <div className="oil-request-page__bid-description-mobile">
          Kommentaar:
        </div>
        <div>
          {bid.comment ? bid.comment : '–'}
        </div>
        <div className="oil-request-page__bid-description-mobile">
          {oilRequestMarkup
            ? `Juurdehindlus:`
            : ""}
        </div>
        <div>
          {oilRequestMarkup
            ? `${numberWithSpaces(new Big(oilRequestMarkup || 0).toFixed(2))} €`
            : ""}
        </div>
        <div className="oil-request-page__bid-description-mobile">
          {oilRequestMarkup
            ? `Summa:`
            : ""}
        </div>
        <div>
          {oilRequestMarkup
            ? `${numberWithSpaces(new Big(oilRequestMarkup || 0).plus(new Big(bid.price || 0)).toFixed(2))} €`
            : ""}
        </div>
        <div className="oil-request-page__bid-description-mobile">
          {oilRequestMarkup
            ? `Summa käibemaksuga:`
            : ""}
        </div>
        <div>
          {oilRequestMarkup
            ? `${numberWithSpaces(getDisplayValueWithTax(new Big(oilRequestMarkup || 0).plus(new Big(bid.price || 0)), 2))} €`
            : ""}
        </div>

        <div className="oil-request-page__bid-description-mobile">Lisatud:</div>
        <div>{date.getDateTime(bid.modDatetime)}</div>
        <div className="oil-request-page__mobile-hide">
          {REQUESTER && REQUEST_STATUS_DECISION && (
            <>
              <Button
                type="button"
                variant="warning"
                ref={this.confirmButtonMobileRef}
                onClick={this.toggleConfirm}
              >
                Kuuluta võitjaks
              </Button>
              <Overlay
                show={this.state.showConfirm}
                rootClose
                onHide={this.hidePopover}
                target={this.confirmButtonMobileRef.current}
              >
                {confirmPopover}
              </Overlay>
            </>
          )}
        </div>
      </>
    );
  }
}

export default Bid;
