import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import Bid from './Bid';

type OilRequestBid = {
  markup: string;
  id: string;
}

type OilRequest = {
  markup?: string;
  status: string;
  user: {
    id: number;
  };
  oils: any[];
}

type Props = {
  oilRequestBids: OilRequestBid[];
  oilRequest: OilRequest;
  onWinner: (bidId: string, markup?: string) => void;
}

const OilRequestBidTable: React.FC<Props> = ({
  oilRequestBids,
  oilRequest,
  onWinner,
}): React.ReactElement => {
  const user = useSelector((state: RootState) => state.userReducer.user);

  if (!oilRequestBids || oilRequestBids.length === 0) {
    return (
      <div>Ei ole tehtud ühtegi pakkumist.</div>
    );
  }

  return (
    <>
      <div className="oil-request-page__bids">
        <div className="oil-request-page__bids-title">Pakkuja</div>
        <div className="oil-request-page__bids-title">Pakkumine</div>
        <div className="oil-request-page__bids-title">{!oilRequest.markup ? 'Pakkumine käibemaksuga 22%' : ""}</div>
        <div className="oil-request-page__bids-title">Kommentaar</div>
        <div className="oil-request-page__bids-title">{oilRequest.markup ? "Juurdehindlus" : ""}</div>
        <div className="oil-request-page__bids-title">{oilRequest.markup ? "Summa" : ""}</div>
        <div className="oil-request-page__bids-title">{oilRequest.markup ? "Summa käibemaksuga" : ""}</div>
        <div className="oil-request-page__bids-title">Lisatud</div>
        <div className="oil-request-page__bids-title" />

        {oilRequestBids.map((bid, i) => (
          <React.Fragment key={bid.id}>
          <Bid
            key={bid.id}
            requestStateCode={oilRequest.status}
            user={user}
            bid={bid}
            oilRequestMarkup={oilRequest.markup}
            oilRequestOils={oilRequest.oils}
            onOilRequestWinner={onWinner}
            requesterId={oilRequest.user.id}
          />
          {i !== oilRequestBids.length - 1 && (
            <hr className="oil-request-page__bid-description-mobile p-0 m-3" />
          )}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default OilRequestBidTable;
