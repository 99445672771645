import React, { PureComponent } from "react";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../../common/Loader";
import {
  authenticationProvidersPassword,
  authenticationProvidersFacebook,
  activateAccount,
  authenticationProvidersGoogle,
} from "../../../services/turgoil-api";
import { validateEmail } from "../../../utils/regex";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import { Routes } from "../../../utils/routes";
import { createAuthentication } from "../../../utils/user";
import { RouteComponentProps, withRouter } from "../../../common/withRouter";
import { connect } from "react-redux";
import { ToastType } from "../../../common/Toasts";
import * as toastActions from "../../../state/toasts";

type MapDispatchToProps = {
  showToast: typeof toastActions.showToast;
};

type Props = RouteComponentProps<{ activationCode: string }> &
  MapDispatchToProps;

class SignInView extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);
    const activationCode = searchParams.get("activationCode");

    this.state = {
      email: "",
      emailError: "",
      passwordError: "",
      password: "",
      error: "",
      loading: !!activationCode,
    };
  }

  componentDidMount() {
    const { showToast } = this.props;

    const searchParams = new URLSearchParams(this.props.location.search);
    const activationCode = searchParams.get("activationCode");
    if (activationCode) {
      activateAccount(activationCode)
        .then(() => {
          showToast({
            title: "Päring õnnestus.",
            text: "Teie kasutaja on edukalt aktiveeritud! Logige sisse, et korraldada vedelkütuse hange.",
            type: ToastType.Success,
          });

          this.setState({
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }

    // this.attachGoogleLogin();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.error) {
      this.setState({
        error: "",
      });
    }
    if (this.state[`${e.target.name}Error`]) {
      this.setState({
        [`${[e.target.name]}Error`]: "",
      });
    }
  };

  validate = () => {
    const { email, password } = this.state;
    const errors: any = {};

    if (!validateEmail(email)) {
      errors.emailError =
        "See e-posti aadress ei ole korrektne. Korrektne on näiteks info@turgoil.com.";
    }

    if (password.length < 5) {
      errors.passwordError = "Parool peab sisaldama vähemalt 5 tähemärki.";
    }

    this.setState({ ...errors });

    return Object.keys(errors).length === 0;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.validate()) {
      return;
    }

    const { email, password } = this.state;
    this.setState({
      loading: true,
      error: "",
    });
    authenticationProvidersPassword(email, password)
      .then(this.navigateAfterAuthentication)
      .catch(this.error);
  };

  private navigateAfterAuthentication = (token: string): void => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const redirectTo = params.get("redirectTo");

    if (redirectTo !== null) {
      createAuthentication(token);
      window.location.href = redirectTo;
    } else {
      createAuthentication(token);
      window.location.href = Routes.OilRequests;
    }
  };

  handleFacebookLogin = () => {
    window.FB.login(
      (res) => {
        authenticationProvidersFacebook(res.authResponse.accessToken)
          .then((authRes) => {
            createAuthentication(authRes);
            window.location.href = Routes.OilRequests;
          })
          .catch(this.error);
      },
      { scope: "public_profile,email" },
    );
  };

  attachGoogleLogin = () => {
    if (!window.auth2) {
      window.setTimeout(() => {
        this.attachGoogleLogin();
      }, 100);
      return;
    }
    window.auth2.attachClickHandler(
      document.getElementById("google-button"),
      {},
      (googleUser) => {
        const accessToken = googleUser.getAuthResponse().id_token;
        authenticationProvidersGoogle(accessToken)
          .then((authRes) => {
            createAuthentication(authRes);
            window.location.href = Routes.OilRequests;
          })
          .catch(this.error);
      },
      (err) => {
        // eslint-disable-next-line
        console.error(err);
      },
    );
  };

  error = (err) => {
    let message = "Kahjuks ei õnnestunud sisse logida.";
    if (err.status === 401) {
      if (err.lockDatetime) {
        message = `Vale e-posti aadress või parool. Proovige
					${moment.duration(moment(err.lockDatetime).diff(moment.utc())).asSeconds().toFixed()} sekundi pärast uuesti.
				`;
      } else {
        message = "Vale e-posti aadress või parool";
      }
    }
    if (err.status === 422) {
      message =
        "Teie kasutaja on aktiveerimata. Aktiveerimise juhend on saadetud Tele e-posti aadressile.";
    }
    this.setState({
      loading: false,
      error: message,
    });

    // Loading removes the initial attachment. It needs to be readded
    // this.attachGoogleLogin();
  };

  render() {
    const { error, loading, email, password, emailError, passwordError } =
      this.state;

    return (
      <>
        {!loading && (
          <>
            <Form onSubmit={this.handleSubmit} noValidate>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="email">E-posti aadress</Form.Label>
                <Form.Control
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  isInvalid={emailError.length}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label htmlFor="password">Parool</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={this.handleChange}
                  isInvalid={passwordError.length || error}
                />
                <Form.Control.Feedback type="invalid">
                  {passwordError} {error}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="warning"
                className="login-page__login-button mb-2"
                type="submit"
              >
                Logi sisse
              </Button>
            </Form>
            <div className="text-center mb-2">või</div>
            <div className="login-page__alternative-login-methods">
              <GoogleButton className="mb-2" />
              {/* <FacebookButton onClick={this.handleFacebookLogin} /> */}
            </div>
            <p className="login-page__forgot-password">
              <Link to="/account-recovery">
                <a href="/account-recovery" className="login-page__link">
                  Unustasid oma salasõna?
                </a>
              </Link>
            </p>
          </>
        )}
        {loading && <Loader />}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(SignInView));
