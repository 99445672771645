import React from "react";
import { jwtDecode } from "jwt-decode";

import Toasts from "../../common/Toasts";
import Protected from "../../common/Protected";
import * as userActions from "../../state/user";
import * as userCompaniesActions from "../../state/user-companies";
import * as userConversationsActions from "../../state/user-conversations";
import * as userNotificationActions from "../../state/user-notifications";
import {
  destroyAuthentication,
  getAuthenticationToken,
} from "../../utils/user";
import { Routes } from "../../utils/routes";
import { Outlet } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ThunkAction } from "redux-thunk";
import { connect } from "react-redux";

type BoundThunk<T extends (...args: any[]) => ThunkAction<any, any, any, any>> =
  (...args: Parameters<T>) => ReturnType<ReturnType<T>>;

type Props = {
  user: any;
  getUser: BoundThunk<typeof userActions.getUser>;
  getUserCompanies: BoundThunk<typeof userCompaniesActions.getUserCompanies>;
  getUserConversations: BoundThunk<
    typeof userConversationsActions.getUserConversations
  >;
  getUserNotifications: BoundThunk<
    typeof userNotificationActions.getUserNotifications
  >;
};

type State = {
  loading: boolean;
};

class App extends React.Component<Props, State> {
  conversationPoller = undefined;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const {
      getUser,
      getUserCompanies,
      getUserConversations,
      getUserNotifications,
    } = this.props;

    if (!this.canAuthenticate()) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });
    const decodedToken = jwtDecode<{ id: string }>(getAuthenticationToken());
    const promises = [];
    promises.push(
      getUserCompanies(decodedToken.id).catch((err) => {
        // eslint-disable-next-line
        console.error(err);
      }),
    );

    promises.push(
      getUser(decodedToken.id).catch((err) => {
        if (err.type === "https://turgoil.com/problems/unauthorized") {
          destroyAuthentication();
          window.location.href = Routes.SignIn;
          return;
        }

        // eslint-disable-next-line
        console.error(err);
      }),
    );

    Promise.all(promises).finally(() => {
      this.setState({ loading: false });
    });

    getUserConversations();
    getUserNotifications(decodedToken.id);

    this.conversationPoller = window.setInterval(() => {
      getUserConversations();
      getUserNotifications(decodedToken.id);
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // User had logged out remove poller
    if (prevProps.user && !user) {
      if (this.conversationPoller) {
        window.clearInterval(this.conversationPoller);
      }
    }
  }

  componentWillUnmount() {
    if (this.conversationPoller) {
      window.clearInterval(this.conversationPoller);
    }
  }

  canAuthenticate = () => {
    const { user } = this.props;
    const token = getAuthenticationToken();
    return token && !user;
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <Toasts />

        {user && (
          <Protected>
            <Outlet />
          </Protected>
        )}
        {!user && <Outlet />}
      </>
    );
  }
}

function mapDispatchToProps(dispatch): any {
  const { getUserCompanies } = bindActionCreators(
    userCompaniesActions,
    dispatch,
  );
  const { getUser } = bindActionCreators(userActions, dispatch);
  const { getUserConversations } = bindActionCreators(
    userConversationsActions,
    dispatch,
  );
  const { getUserNotifications } = bindActionCreators(
    userNotificationActions,
    dispatch,
  );

  return {
    getUser,
    getUserCompanies,
    getUserConversations,
    getUserNotifications,
  };
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
