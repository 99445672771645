import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import { Alert, Form, Button } from "react-bootstrap";
import Select from "../../common/Select";
import Date from "../../common/Date";
import Loader from "../../common/Loader";
import { createOilRequest } from "../../services/turgoil-api";
import { getDateTime } from "../../utils/date";
import {
  oilRequestTransportOptions,
  oilRequestLocationOptions,
} from "./helpers";
import { ToastType } from "../../common/Toasts";
import * as toastActions from "../../state/toasts";
import { OilRequestOilType } from "../../utils/config";
import OilForm, { Oil } from "./sub/OilForm";
import { RootState } from "../../state/store";
import { Dispatch } from "redux";

moment.locale("et");

type Props = {
  companies: any[];
  user: any;
  showToast: typeof toastActions.showToast;
};

type State = {
  userId: string;
  companyId: string;
  endDatetime: string;
  paymentDateAsNumber: string;
  transportDate: string;
  transportCode: string;
  address: string;
  description: string;
  errors: Record<string, string>;
  loading: boolean;
  isSuccess: boolean;
  oils: Oil[];
  oilsErrors: Record<string, Partial<Record<keyof Oil, string>>>
};

const oilOptions = [
  {
    value: OilRequestOilType.Petrol95,
    label: "Bensiin 95",
  },
  {
    value: OilRequestOilType.Petrol98,
    label: "Bensiin 98",
  },
  {
    value: OilRequestOilType.Diesel,
    label: "Diislikütus",
  },
  {
    value: OilRequestOilType.DieselSpecial,
    label: "Erimärgistusega diislikütus",
  },
  {
    value: OilRequestOilType.HydrotreatedVegetableOil,
    label: "HVO-biokütus",
  },
];

class CreateOilRequestPage extends Component<Props, State> {
  private oilRequestLocationOptions: typeof oilRequestLocationOptions;
  private oilRequestRequesterOptions: any;

  constructor(props) {
    super(props);

    this.oilRequestLocationOptions = oilRequestLocationOptions;
    this.oilRequestRequesterOptions = this.availableOilRequestRequesters;

    this.state = {
      userId: "",
      companyId: "",
      endDatetime: "",
      paymentDateAsNumber: "",
      transportDate: "",
      transportCode: "",
      address: "",
      description: "",
      oils: [],
      errors: {},
      oilsErrors: {},
      loading: false,
      isSuccess: false,
    };
  }

  get availableOilRequestRequesters() {
    const { user, companies } = this.props;

    const res = [
      {
        label: "Eraisik",
        options: [
          {
            value: `u${user.id}`,
            label: `${user.forename} ${user.surname}`,
            name: "userId",
          },
        ],
      },
    ];

    if (companies.length) {
      const companyOptions = companies.map((c) => ({
        value: `c${c.id}`,
        label: c.companyName,
        name: "companyId",
      }));
      res[1] = {
        label: "Ettevõtted",
        options: companyOptions,
      };
    }

    return res;
  }

  handleTransportCodeChange = (option) => {
    if (!option) {
      this.setState((prevState) => ({
        transportCode: null,
        errors: {
          ...prevState.errors,
          transportCode: "",
        },
      }));

      this.oilRequestLocationOptions = oilRequestLocationOptions;
      return;
    }

    if (option.value === "Requester") {
      this.oilRequestLocationOptions = [
        {
          value: "Harjumaa",
          label: "Harjumaa",
        },
        {
          value: "Tartumaa",
          label: "Tartumaa",
        },
      ];
    } else {
      this.oilRequestLocationOptions = oilRequestLocationOptions;
    }

    this.setState((prevState) => ({
      transportCode: option.value,
      errors: {
        ...prevState.errors,
        transportCode: "",
      },
    }));
  };

  handleAddressChange = (option) => {
    if (!option) {
      this.setState((prevState) => ({
        address: "",
        errors: {
          ...prevState.errors,
          address: "",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      address: option.value,
      errors: {
        ...prevState.errors,
        address: "",
      },
    }));
  };

  handleOilRequesterChange = (option) => {
    if (!option) {
      this.setState((prevState) => ({
        userId: "",
        companyId: "",
        errors: {
          ...prevState.errors,
          requester: "",
        },
      }));

      return;
    }

    if (option.name === "companyId") {
      this.setState((prevState) => ({
        userId: "",
        companyId: option.value.slice(1),
        errors: {
          ...prevState.errors,
          requester: "",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      userId: option.value.slice(1),
      companyId: "",
      errors: {
        ...prevState.errors,
        requester: "",
      },
    }));
  };

  handlePaymentDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    this.setState((prevState) => ({
      paymentDateAsNumber: value,
      errors: {
        ...prevState.errors,
        paymentDateAsNumber: "",
      },
    }));
  };

  handleTransportDateChange = (date) => {
    let d = date;
    if (d instanceof moment) {
      (d as Moment).set({
        second: 0,
        millisecond: 0,
      });
      d = date.format("YYYY-MM-DD HH:mm:ss");
    }
    this.setState((prevState) => ({
      transportDate: d,
      errors: {
        ...prevState.errors,
        transportDate: "",
      },
    }));
  };

  handleRequestEndDatetimeChange = (date) => {
    let d = date;
    if (d instanceof moment) {
      (d as Moment).set({
        second: 0,
        millisecond: 0,
      });
      d = date.format("YYYY-MM-DD HH:mm:ss");
    }

    this.setState((prevState) => ({
      endDatetime: d,
      errors: {
        ...prevState.errors,
        endDatetime: "",
      },
    }));
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      description: value,
      errors: {
        ...prevState.errors,
        description: "",
      },
    }));
  };

  handleOilChange = (oilChangeEvent: Oil) => {
    this.setState((prevState) => ({
      oils: prevState.oils.map((o) => {
        if (o.oilCode === oilChangeEvent.oilCode) {
          return oilChangeEvent;
        }

        return o;
      }),
      oilsErrors: {
        ...prevState.oilsErrors,
        [oilChangeEvent.oilCode]: {}
      },
      errors: {
        ...prevState.errors,
        oil: "",
      },
    }));
  };

  renderOilRequestChooseWinnerDatetimeRange = () => {
    const { endDatetime } = this.state;

    const datetime = moment(endDatetime);
    if (datetime.isValid()) {
      const dueDate = moment(datetime).add(4, "hours");
      let dueDateString = dueDate.format("D. MMMM HH:mm");
      if (datetime.isSame(dueDate, "day")) {
        dueDateString = dueDate.format("HH:mm");
      }
      return `${datetime.format("D. MMMM HH:mm")} – ${dueDateString}`;
    }

    return "–";
  };

  disableUnavailableDates = (current) => {
    const choosingWinnerDueDate = moment(this.state.endDatetime);
    if (choosingWinnerDueDate.isValid()) {
      choosingWinnerDueDate.add(2, "hours");
      return current.isAfter(choosingWinnerDueDate.subtract(1, "day"));
    }

    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  private validate = (): boolean => {
    const errors: Record<string, string> = {};
    const oilsErrors: Record<string, Partial<Record<keyof Oil, string>>> = {
      [`${OilRequestOilType.DieselSpecial}`]: {},
      [`${OilRequestOilType.Diesel}`]: {},
      [`${OilRequestOilType.Petrol95}`]: {},
      [`${OilRequestOilType.Petrol98}`]: {},
      [`${OilRequestOilType.HydrotreatedVegetableOil}`]: {},
    };

    const requesterAsUserOrCompany =
      !!this.state.userId || !!this.state.companyId;

    if (!requesterAsUserOrCompany) {
      errors.requester = "See väli on kohustuslik!";
    }

    const requestEndDatetime = moment(this.state.endDatetime);
    if (!this.state.endDatetime) {
      errors.endDatetime = "See väli on kohustuslik!";
    } else {
      const maxAllowedDate = moment().add(2, "weeks").endOf("day");
      const minAllowedDate = moment().add(15, "minutes");
      if (!requestEndDatetime.isValid()) {
        errors.endDatetime = "Kuupäeva formaat ei ole korretkne!";
      } else if (requestEndDatetime.isAfter(maxAllowedDate)) {
        errors.endDatetime = `Hanke maksimaalne lubatud lõppkuupäev on ${getDateTime(maxAllowedDate.toISOString())}`;
      } else if (requestEndDatetime.isBefore(minAllowedDate)) {
        errors.endDatetime = `Hanke minimaalne lubatud lõppkuupäev on ${getDateTime(minAllowedDate.toISOString())}`;
      }
    }

    if (this.state.paymentDateAsNumber === undefined) {
      errors.paymentDateAsNumber = "See väli on kohustuslik!";
    } else if (parseInt(this.state.paymentDateAsNumber, 10) < 0) {
      errors.paymentDateAsNumber = "Mäksetähtaeg ei tohi olla minevikus";
    }

    if (!this.state.transportDate) {
      errors.transportDate = "See väli on kohustuslik!";
    } else {
      const transportDate = moment(this.state.transportDate);
      if (!transportDate.isValid()) {
        errors.transportDate = "Kuupäeva formaat ei ole korretkne!";
      } else if (
        requestEndDatetime.isValid() &&
        transportDate.isBefore(requestEndDatetime.startOf("day"))
      ) {
        errors.transportDate =
          "Tarnetähtaeg peab olema hilsem kui hanke lõppkuupäev!";
      }
    }

    if (!this.state.address) {
      errors.address = "See väli on kohustuslik!";
    } else if (
      this.state.transportCode === "Requester" &&
      !this.oilRequestLocationOptions.some(
        (o) => o.value === this.state.address,
      )
    ) {
      errors.address = "Asukoht peab olema kas Tartumaa või Harjumaa.";
    }

    if (!this.state.transportCode) {
      errors.transportCode = "See väli on kohustuslik!";
    }

    this.state.oils.forEach((o) => {
      const amount = parseInt(o.amount, 10) || 0;
      if (amount < 1000) {
        oilsErrors[o.oilCode].amount = "Kütuse kogus peab olema vähemalt 1 000 L.";
      }
      if (amount > Number.MAX_VALUE) {
        oilsErrors[o.oilCode].amount = "Kütuse kogus ületab lubatud limiidi.";
      }
      // if ([`${OilRequestOilType.Petrol98}`, `${OilRequestOilType.Petrol95}`].includes(o.oilCode) && o.marking === undefined) {
      //   oilsErrors[o.oilCode].marking = "See väli on kohustuslik";
      // }
      // if ([`${OilRequestOilType.Diesel}`, `${OilRequestOilType.DieselSpecial}`].includes(o.oilCode) && o.hasBioAdditive === undefined) {
      //   oilsErrors[o.oilCode].hasBioAdditive = "See väli on kohustuslik";
      // }
      if ([`${OilRequestOilType.DieselSpecial}`].includes(o.oilCode) && o.purpose === undefined) {
        oilsErrors[o.oilCode].purpose = "See väli on kohustuslik";
      }
    });
    if (this.state.oils.length === 0) {
      errors.oil = "Vähemalt üks kütus on kohustuslik!";
    }

    this.setState({
      errors,
      oilsErrors,
      isSuccess: false,
    });

    return Object.keys(errors).length === 0 && !Object.values(oilsErrors).some(e => Object.keys(e).length > 0);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { showToast } = this.props;

    if (!this.validate()) {
      showToast({
        title: "Päring ebaõnnestus",
        text: "Palun parandage vead!",
        type: ToastType.Error,
      });

      return;
    }

    const {
      endDatetime,
      paymentDateAsNumber,
      companyId,
      transportDate,
      transportCode,
      address,
      description,
      oils,
    } = this.state;

    this.setState({ loading: true });
    const req: any = {
      endDatetime: moment(endDatetime).format(),
      paymentDate: undefined,
      transportDate,
      transportCode,
      address,
      description,
      oils: [
        ...oils
          .filter((o) => parseInt(o.amount, 10) >= 1000)
          .map((o) => ({
            amount: o.amount,
            type: o.oilCode,
          })),
      ],
    };

    if (companyId) {
      req.companyId = companyId;
    }

    const found = req.oils.find((o) => o.type === OilRequestOilType.DieselSpecial);
    if (found) {
      found.usageType = oils.find((o) => o.oilCode === OilRequestOilType.DieselSpecial).purpose;
    }

    let d = moment(endDatetime).add(paymentDateAsNumber, "days");
    (d as Moment).set({
      second: 0,
      millisecond: 0,
    });
    req.paymentDate = d.format("YYYY-MM-DD HH:mm:ss");

    createOilRequest(req)
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Õnnitleme, Teie hange on edukalt korraldatud ning ilmub mõne hetke pärast!",
          type: ToastType.Success,
        });

        this.setState({
          isSuccess: true,
          companyId: "",
          userId: "",
          endDatetime: "",
          paymentDateAsNumber: "",
          transportDate: "",
          transportCode: "",
          address: "",
          description: "",
          oils: [],
          errors: {},
          oilsErrors: {},
          loading: false,
        });
      })
      .catch(() => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: "Vabandame, midagi läks valesti. Palun proovi mõne aja pärast uuesti.",
          type: ToastType.Error,
        });

        this.setState({
          loading: false,
          companyId: "",
          endDatetime: "",
          paymentDateAsNumber: "",
          transportDate: "",
          transportCode: "",
          address: "",
          description: "",
          oils: [],
          errors: {},
          oilsErrors: {},
        });
      });
  };

  validateRequestEndDatetime = (currentDate) => {
    const todayAfterTwoWeeks = moment().add(2, "weeks");
    return (
      currentDate.isBefore(todayAfterTwoWeeks) &&
      currentDate.isSameOrAfter(moment().startOf("day"))
    );
  };

  handleOilSelect = (oils) => {
    this.setState((prevState) => ({
      oils: oils.map((o) => {
        const existingOil = prevState.oils.find((prevOil) => prevOil.oilCode === o.value);
        if (existingOil) {
          return existingOil;
        }

        return {
          oilCode: o.value,
          amount: "",
        }
      }),
    }));
  }

  private getTitleByOilType(oilCode: string): string {
    if (OilRequestOilType.Diesel === oilCode) {
      return 'Diislikütus';
    }
    if (OilRequestOilType.Petrol95 === oilCode) {
      return 'Bensiin 95';
    }
    if (OilRequestOilType.Petrol98 === oilCode) {
      return 'Bensiin 98';
    }
    if (OilRequestOilType.DieselSpecial === oilCode) {
      return 'Erimärgistusega diislikütus';
    }
    if (OilRequestOilType.HydrotreatedVegetableOil === oilCode) {
      return 'HVO-biokütus';
    }
  }

  render() {
    const { companies } = this.props;
    const { oils, oilsErrors } = this.state;

    return (
      <>
        <Helmet>
          <title>Turgoil - Uus hange</title>
        </Helmet>
        <h3 className="page-content__title">Uus hange</h3>
        {this.state.loading && <Loader />}
        {!this.state.loading && (
          <Form onSubmit={this.handleSubmit}>
            {!companies.length && (
              <Alert variant="warning">
                Tundub, et Teil ei ole lisatud ühtegi ettevõtet. Juhul kui
                soovite korraldada hanget juriidilise isikuna, siis ettevõtteid
                saate lisada{" "}
                <Link to="/settings" className="alert-link">
                  seadete lehel
                </Link>
                .
              </Alert>
            )}
            <div className="create-request">
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Hanke korraldaja</Form.Label>
                    <Form.Text muted>
                      Hanke korraldajat näevad ainult pakkujad, see aitab neil
                      paremini pakkumist koostada.
                    </Form.Text>
                    <Select
                      isClearable
                      onChange={this.handleOilRequesterChange}
                      placeholder=""
                      options={this.oilRequestRequesterOptions}
                      isInvalid={Boolean(this.state.errors.requester)}
                    />

                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.requester}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Hanke lõppkuupäev</Form.Label>
                    <Form.Text muted>
                      See on kuupäev millal hange läheb "võitja valimise"
                      etappi. Pärast seda ei ole võimalik pakkumisi teha ega
                      muuta.
                    </Form.Text>
                    <Date
                      onChange={this.handleRequestEndDatetimeChange}
                      initialViewDate={moment().add(30, "minutes")}
                      isInvalid={Boolean(this.state.errors.endDatetime)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.endDatetime}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">
                      {" "}
                      Võitja valimise ajavahemik
                    </Form.Label>
                    <Form.Text muted>
                      Selles ajavahemikus tuleb hankele võitja valida.{" "}
                      <strong>
                        NB! Kui hankele ei ole tehtud ühtegi pakkumist, siis
                        jääb "võitja valimise" etapp vahele.
                      </strong>
                    </Form.Text>
                    <div className="create-request__time-duration">
                      {this.renderOilRequestChooseWinnerDatetimeRange()}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Tarnetähtaeg</Form.Label>
                    <Form.Text muted>
                      See on kuupäev millal toimub tarne.
                    </Form.Text>
                    <Date
                      onChange={this.handleTransportDateChange}
                      timeFormat={false}
                      isInvalid={Boolean(this.state.errors.transportDate)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.transportDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Maksetähtaeg</Form.Label>
                    <Form.Text muted>
                      Numbriline väärtus päevades, millal olete võimelised
                      tasuma hanke võitjale kütuse eest.
                    </Form.Text>
                    <Form.Control
                      type="number"
                      onChange={this.handlePaymentDateChange}
                      name="paymentDateAsNumber"
                      isInvalid={!!this.state.errors.paymentDateAsNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.paymentDateAsNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Transport</Form.Label>
                    <Form.Text muted>
                      Kui transport on võitja poolt, siis toob hanke võitja
                      kütuse soovitud asukohta. Kui transport on korraldaja
                      poolt, siis peab korraldaja ise kütusele järgi minema.
                    </Form.Text>
                    <Select
                      isClearable
                      onChange={this.handleTransportCodeChange}
                      placeholder=""
                      options={oilRequestTransportOptions}
                      isInvalid={Boolean(this.state.errors.transportCode)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.transportCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Asukoht</Form.Label>
                    <Form.Text muted>
                      See on asukoht, kus toimub tarne.{" "}
                      <strong>
                        Kui soovite lisada täpsustavat informatsiooni, siis
                        lisage see kirjelduses.
                      </strong>
                    </Form.Text>
                    <Select
                      isClearable
                      onChange={this.handleAddressChange}
                      placeholder=""
                      options={this.oilRequestLocationOptions}
                      isInvalid={Boolean(this.state.errors.address)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-0">Kirjeldus</Form.Label>
                    <Form.Text muted>
                      Sisestage siia sobiv kirjeldus, mis aitaks pakkujatel
                      paremini pakkumisi teha. Näiteks võite siin täpsustada
                      tarne asukohta ja tarne tähtaega. Kui Teil on vaja kütust
                      teatud kuupäevadel, siis siin saab seda täpsustada. Hanke
                      kirjeldust näevad ainult pakkujad.
                      <strong>
                        NB! Kontaktandmete jagamine on rangelt keelatud.
                      </strong>
                    </Form.Text>
                    <textarea
                      name="description"
                      onChange={this.handleChange}
                      spellCheck="false"
                      className="form-control"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <Form.Group className="col-sm-12 mb-2">
                  <Form.Label className="create-request__description-label">
                    Kütused
                  </Form.Label>
                  <Select
                    options={oilOptions}
                    onChange={this.handleOilSelect}
                    value={oilOptions.filter((o) => oils.some((oo) => oo.oilCode === o.value))}
                    placeholder=""
                    isMulti
                  />
                </Form.Group>
              </div>

              <div className="row">
                {oils.map((oil) => (
                  <OilForm
                    key={oil.oilCode}
                    title={this.getTitleByOilType(oil.oilCode)}
                    oilCode={oil.oilCode}
                    onChange={this.handleOilChange}
                    error={oilsErrors[oil.oilCode]}
                  />
                ))}
              </div>
              <div className="invalid-feedback d-block m-0">
                {this.state.errors.oil}
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="create-request__button-container">
                    <Button
                      variant="warning"
                      type="submit"
                    >
                      Korralda hange
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companies: state.userCompaniesReducer.companies,
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOilRequestPage);
