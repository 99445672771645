import React, { PureComponent } from "react";
import classNames from "classnames";
import ReactSelect, { ActionMeta } from "react-select";
import Option from "react-select/dist/declarations/src/components/Option";

function getColor(isSelected, isFocused) {
  if (isSelected) {
    return "#ffc107";
  }
  if (isFocused) {
    return "#ebebeb";
  }
  return "#fff";
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#212529",
    backgroundColor: getColor(state.isSelected, state.isFocused),
    "&:hover": { backgroundColor: state.isSelected ? "#ffc107" : "#ebebeb" },
  }),
};

type Props = {
  options: any[];
  value: any;
  name?: string;
  onChange: (value: { value: any }, actionMeta: ActionMeta<{ name?: string }>) => void;
  isClearable?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  isMulti?: boolean;
};

class Select extends PureComponent<Props> {
  static defaultProps = {
    isClearable: true,
    placeholder: "",
    isInvalid: false,
    isMulti: false,
    value: undefined,
  };

  private input: any = React.createRef();

  handleChange = (option, meta) => {
    if (this.input.current) {
      this.input.current.blur();
    }

    this.props.onChange(option, meta);
  };

  noOptions = () => "Vastet ei leitud";

  render() {
    const { options, isClearable, placeholder, isInvalid, isMulti, value } =
      this.props;

    return (
      <ReactSelect
        className={classNames("select", { "is-invalid": isInvalid })}
        classNamePrefix="select"
        ref={this.input}
        name={this.props.name}
        options={options}
        styles={customStyles}
        onChange={this.handleChange}
        noOptionsMessage={this.noOptions}
        isClearable={isClearable}
        placeholder={placeholder}
        isMulti={isMulti}
        value={value}
      />
    );
  }
}

export default Select;
