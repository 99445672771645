import React from "react";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import { destroyAuthentication, getRoleDisplayName } from "../../../utils/user";
import { Routes } from "../../../utils/routes";
import { selectUserDisplayName } from "../../../state/user-companies";
import { UserRole } from "../../../utils/config";

function isConversations(path) {
  const reConversations = /\/conversations/g;
  return reConversations.test(path);
}

type Props = {
  pathname: string;
  onToggle: () => void;
  show: boolean;
  isMobile: boolean;
  user: any;
  userCompanies: any[];
  userConversations: any[];
  userNotifications: any[];
  userDisplayName?: string;
};

class ProtectedSidebar extends React.PureComponent<Props> {
  handleLogout = (e) => {
    e.preventDefault();
    destroyAuthentication();
    window.location.href = Routes.SignIn;
  };

  getUnreadConversations = () => {
    const { user, userCompanies, userConversations, userNotifications } =
      this.props;

    let count = 0;
    userConversations.forEach((conversation) => {
      const lastMessage = conversation.messages[0];
      if (!lastMessage) {
        return;
      }

      if (lastMessage.from.userId === user.id) {
        return;
      }
      if (
        lastMessage.from.companyId &&
        userCompanies.some(
          (company) => company.id === lastMessage.from.companyId,
        )
      ) {
        return;
      }

      if (!lastMessage.readDatetime) {
        count += 1;
      }
    });

    const lastNotification = userNotifications.slice(-1).pop();
    if (lastNotification && !lastNotification.readDatetime) {
      count += 1;
    }

    return count;
  };

  render() {
    const { pathname, isMobile, show, onToggle, user, userDisplayName } =
      this.props;

    const unreadConversations = this.getUnreadConversations();

    return (
      <>
        {isMobile && show && (
          <div className="button-overlay" onClick={onToggle} />
        )}
        <div
          className={`protected-sidebar ${isMobile && show ? "protected-sidebar--show" : ""}`}
        >
          <ul>
            <li>
              {isMobile && show && (
                <div className="close-container">
                  {/* <Cross height="20px" fill="#ffc107" /> */}
                </div>
              )}

              <Link to="/oil-requests">
                <a href="/" className="protected__no-hover" onClick={onToggle}>
                  <img
                    className="protected-sidebar__image"
                    src="/images/svg/turgoil-logo.svg"
                    alt="logo"
                  />
                </a>
              </Link>
            </li>
            <li>
              <span className="protected-sidebar__person">
                <i className="bi bi-person"></i>
                <div className="d-flex flex-column">
                  <div style={{ lineHeight: 1 }}>{userDisplayName}</div>
                  <small style={{ lineHeight: 1 }}>
                    <i>
                      {getRoleDisplayName(user.role)}
                    </i>
                  </small>
                </div>
              </span>
            </li>
            <hr />
            <li>
              <Link
                to="/oil-requests/create"
                className={`protected-sidebar__link ${pathname === "/oil-requests/create" ? "protected-sidebar-active" : ""}`}
                onClick={onToggle}
              >
                <i className="bi bi-plus-circle"></i>
                Uus hange
              </Link>
            </li>
            <li>
              <Link
                to="/oil-requests"
                className={`protected-sidebar__link ${pathname === "/oil-requests" ? "protected-sidebar-active" : ""}`}
                onClick={onToggle}
              >
                <i className="bi bi-files"></i>
                {user.role === UserRole.Bidder ? 'Hanked' : 'Minu hanked'}
              </Link>
            </li>
            <li>
              <Link
                to="/conversations"
                className={`protected-sidebar__link ${isConversations(pathname) ? "protected-sidebar-active" : ""}`}
                onClick={onToggle}
              >
                <i className="bi bi-bell"></i>
                Sõnumid
                {unreadConversations > 0 && (
                  <Badge bg="warning" text="dark">
                    {unreadConversations}
                  </Badge>
                )}
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className={`protected-sidebar__link ${pathname === "/settings" ? "protected-sidebar-active" : ""}`}
                onClick={onToggle}
              >
                <i className="bi bi-gear"></i>
                Seaded
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={this.handleLogout}
                className="protected-sidebar__link"
              >
                <i className="bi bi-box-arrow-left"></i>
                Logi välja
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userConversations: state.userConversationsReducer.conversations,
    user: state.userReducer.user,
    userCompanies: state.userCompaniesReducer.companies,
    userNotifications: state.userNotificationsReducer.notifications,
    userDisplayName: selectUserDisplayName(state),
  };
}

export default connect(mapStateToProps)(ProtectedSidebar);
