import React from "react";
import { createAuthentication } from "../../../utils/user";
import { authenticationProvidersGoogle } from "../../../services/turgoil-api";
import { Routes } from "../../../utils/routes";

type Props = {
  className: string;
};

window.googleCallback = (response): void => {
  const accessToken = response.credential;
  authenticationProvidersGoogle(accessToken)
    .then((authRes) => {
      createAuthentication(authRes);
      window.location.href = Routes.OilRequests;
    })
    .catch((err) => {
      console.error(err);
    });
};

class GoogleButton extends React.PureComponent<Props> {
  render() {
    return (
      <div className="mb-2 d-flex justify-content-center">
        {/* <button */}
        {/*   className={classNames("google-button", className)} */}
        {/*   type="button" */}
        {/*   id="google-button" */}
        {/* > */}
        {/*   <div className="google-button__icon"> */}
        {/*     <img src="images/svg/google.svg" alt="Google" /> */}
        {/*   </div> */}
        {/*   <span>Logi sisse Google'iga</span> */}
        {/* </button> */}
        <div
          id="g_id_onload"
          data-client_id="598363213550-d6jtctp6luujtg6la7h6cu57cvuof9ns.apps.googleusercontent.com"
          data-callback="googleCallback"
          data-auto_prompt="false"
        ></div>
        <div
          className="g_id_signin"
          data-locale="et"
          data-type="standard"
          data-size="large"
          data-theme="outline"
          data-text="sign_in_with"
          data-shape="rectangular"
          data-logo_alignment="left"
        ></div>

        {/* <div id="g_id_onload" */}
        {/*      data-client_id="3" */}
        {/*      data-context="signin" */}
        {/*      data-ux_mode="redirect" */}
        {/*      data-login_uri="2" */}
        {/*      data-auto_prompt="false"> */}
        {/* </div> */}

        {/* <div className="g_id_signin" */}
        {/*      data-type="standard" */}
        {/*      data-shape="rectangular" */}
        {/*      data-theme="outline" */}
        {/*      data-text="signin_with" */}
        {/*      data-size="large" */}
        {/*      data-logo_alignment="left"> */}
        {/* </div> */}
      </div>
    );
  }
}

export default GoogleButton;
