import React, { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Form,
  Badge,
} from "react-bootstrap";

import Loader from "../../common/Loader";
import Oils from "../../common/Oils";
import * as date from "../../utils/date";
import * as toastActions from "../../state/toasts";
import * as Service from "../OilRequestsPage/helpers";
import CreateOrEditBid from "./sub/CreateOrEditBid";
import Clock from "./sub/Clock";
import {
  createOilRequestQuestion,
  getOilRequestQuestions,
  answerOilRequestQuestion,
  setOilRequestWinner,
  getOilRequest,
  getOilRequestBids,
} from "../../services/turgoil-api";
import { ToastType } from "../../common/Toasts";
import { RouteComponentProps, withRouter } from "../../common/withRouter";
import OilRequestBidTable from "./sub/OilRequestBidTable";
import StampOilRequestAsChoosingWinner from "./sub/StampOilRequestAsChoosingWinner";

moment.locale("et");

type MapStateToProps = {
  user: any;
};

type MapDispatchToProps = {
  showToast: any;
};

type Props = RouteComponentProps<{ id: string }> &
  MapStateToProps &
  MapDispatchToProps;

class OilRequestPage extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      oilRequest: null,
      oilRequestBids: [],
      loading: false,
      question: "",
      findRequest: "",
      questionErrors: {},
      questionAnswerErrors: {},
      oilRequestQuestions: [],
    };
  }

  componentDidMount() {
    this.getOilRequest();
  }

  getOilRequest = () => {
    const { params } = this.props;
    const { id } = params;

    const promises = [];
    promises.push(getOilRequest(id));
    promises.push(getOilRequestBids(id as any));
    promises.push(getOilRequestQuestions(id));

    this.setState({ loading: true });
    Promise.all(promises)
      .then(([oilRequest, oilRequestBids, oilRequestQuestions]) => {
        this.setState({
          oilRequest,
          oilRequestBids,
          oilRequestQuestions,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
        });
      });
  };

  handleOilRequestWinner = (bidId: any, markup?: string) => {
    const { showToast } = this.props;

    setOilRequestWinner(this.state.oilRequest.id, {
      bidId,
      markup: markup ? markup : undefined,
    })
      .then(() => {
        this.getOilRequest();
        showToast({
          title: "Päring õnnestus.",
          text: "Võitja edukalt valitud.",
          type: ToastType.Success,
        });
      })
      .catch(() => {
        // TODO
      });
  };

  getQuestions = () => {
    const { oilRequestQuestions, oilRequest } = this.state;
    const { user } = this.props;

    if (!oilRequestQuestions.length) {
      return "Ei ole esitatud ühtegi küsimust.";
    }

    const listOfQuestions = oilRequestQuestions.map((question) => (
      <div key={question.id} className="question">
        <div className="question__heading">
          <span className="question__heading__question">
            {question.question}
          </span>
          <span className="question__heading__question-date">
            {date.getDateTime(question.regDatetime)}
          </span>
        </div>

        {question.answer && (
          <div className="question__answer">{question.answer}</div>
        )}
        {!question.answer && oilRequest.status === "Active" && (
          <>
            {Service.canAnswerQuestion(user, oilRequest) ? (
              <div className="question__answer">
                {this.getAnswerInput(question)}
              </div>
            ) : (
              <div className="question__answer">
                Hanke korraldaja pole küsimusele veel vastanud.
              </div>
            )}
          </>
        )}
      </div>
    ));

    return <div className="question">{listOfQuestions}</div>;
  };

  answerQuestion = (e) => {
    const { showToast } = this.props;
    e.preventDefault();

    const { oilRequest } = this.state;
    const questionId = e.target.attributes.getNamedItem("data-value").value;

    if (!this.state[`question${questionId}`]) {
      this.setState({
        questionAnswerErrors: {
          [questionId]: "See väli on kohustuslik.",
        },
      });

      return;
    }
    this.setState({ questionAnswerErrors: {} });

    answerOilRequestQuestion(oilRequest.id, questionId, {
      answer: this.state[`question${questionId}`],
    })
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Küsimusele edukalt vastatud!",
          type: ToastType.Success,
        });
        this.getOilRequest();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAnswerInput = (question) => {
    const { questionAnswerErrors } = this.state;

    return (
      <div>
        <div className="oil-request-page__question-creation">
          <Form.Control
            as="textarea"
            name={`question${question.id}`}
            onChange={this.handleChange}
            className="form-control"
            isInvalid={Boolean(questionAnswerErrors[question.id])}
          />
          <Form.Control.Feedback type="invalid">
            {questionAnswerErrors[question.id]}
          </Form.Control.Feedback>
        </div>
        <button
          className="btn btn-warning"
          type="button"
          data-value={question.id}
          onClick={this.answerQuestion}
        >
          Vasta
        </button>
      </div>
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorNotFound: "",
    });
  };

  validateQuestion = () => {
    const { question } = this.state;
    const questionErrors: Record<string, string> = {};

    if (!question) {
      questionErrors.question = "See väli on kohustuslik.";
    }

    this.setState({ questionErrors });

    return Object.keys(questionErrors).length === 0;
  };

  createRequestQuestion = (e) => {
    const { params, showToast } = this.props;
    e.preventDefault();

    if (!this.validateQuestion()) {
      return;
    }

    const { question } = this.state;
    const { id } = params;

    createOilRequestQuestion(id, question.trim())
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Küsimus edastatud hankijale!",
          type: ToastType.Success,
        });
        this.getOilRequest();
        this.setState({ question: "" });
      })
      .catch(() => {});
  };

  handleFindSubmit = (e) => {
    e.preventDefault();
    if (!this.state.findRequest) {
      this.setState({
        loading: false,
        errorNotFound: true,
      });
      return;
    }
    getOilRequest(this.state.findRequest)
      .then((oilRequest) => {
        this.setState({
          oilRequest,
          loading: false,
          errorNotFound: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          errorNotFound: true,
        });
      });
  };

  getWinnerChoosingDueDate = (d) =>
    date.getDateTime(moment(d).add(4, "hours").toISOString());

  render() {
    const { user } = this.props;
    const { question, oilRequest, questionErrors, loading, oilRequestBids } =
      this.state;
    let color;
    let size = "lg";
    if (oilRequest) {
      color = Service.getOilRequestStatusColor(oilRequest.status);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      size = "lg";
    }

    return (
      <>
        {loading && <Loader />}
        {!loading && oilRequest && (
          <>
            <div className="oil-request-page__introduction">
              <h3 className="oil-request-page__introduction-title">
                <div className="oil-request-page__introduction-request">
                  {Service.getOilRequestTitle(
                    oilRequest.id,
                    oilRequest.company ? oilRequest.company.id : undefined,
                  )}
                </div>
                <Badge
                  pill
                  bg={Service.getOilRequestStatusColor2(oilRequest.status)}
                  style={{ marginLeft: "auto" }}
                >
                  {Service.getOilRequestStatusName(oilRequest.status)}
                </Badge>
              </h3>
              {/* <span */}
              {/*   className="oil-request-page__state" */}
              {/*   style={{ background: color }} */}
              {/* > */}
              {/* </span> */}
            </div>

            <section>
              <div className="row">
                <div className="col-sm-12">
                  <h5 className="oil-request-page__heading">Hanke kirjeldus</h5>
                  <hr />
                  <div className="oil-request-page__info">
                    <div className="oil-request-page__title">Korraldaja:</div>
                    <div>
                      {oilRequest.company
                        ? oilRequest.company.name
                        : oilRequest.user.name}
                    </div>
                    {oilRequest.company && (
                      <>
                        <div className="oil-request-page__title">Telefon:</div>
                        <div>
                          {oilRequest.company.contactPhone
                            ? oilRequest.company.contactPhone
                            : "–"}
                        </div>
                        <div className="oil-request-page__title">
                          E-posti aadress:
                        </div>
                        <div>
                          {oilRequest.company.contactEmail
                            ? oilRequest.company.contactEmail
                            : "–"}
                        </div>
                      </>
                    )}
                    {!oilRequest.company && (
                      <>
                        <div className="oil-request-page__title">Telefon:</div>
                        <div>
                          {oilRequest.user.contactPhone
                            ? oilRequest.user.contactPhone
                            : "–"}
                        </div>
                        <div className="oil-request-page__title">
                          E-posti aadress:
                        </div>
                        <div>
                          {oilRequest.user.contactEmail
                            ? oilRequest.user.contactEmail
                            : "–"}
                        </div>
                      </>
                    )}
                    <Clock
                      request={this.state.oilRequest}
                      onUpdate={this.getOilRequest}
                    />
                    <div className="oil-request-page__title">Hanke lõpp:</div>
                    <div>{date.getDateTime(oilRequest.endDatetime)}</div>
                    <div className="oil-request-page__title">
                      Võitja valimise tähtaeg:
                    </div>
                    <div>
                      {this.getWinnerChoosingDueDate(oilRequest.endDatetime)}
                    </div>
                    <div className="oil-request-page__title">Maksetähtaeg:</div>
                    <div>{date.getDate(oilRequest.paymentDate)}</div>
                    <div className="oil-request-page__title">Tarnetähtaeg:</div>
                    <div>{date.getDate(oilRequest.transportDate)}</div>
                    <div className="oil-request-page__title">Transport:</div>
                    <div>
                      {Service.getOilRequestTransportName(
                        oilRequest.transportCode,
                      )}
                    </div>
                    <div className="oil-request-page__title">Asukoht:</div>
                    <div>{oilRequest.address}</div>
                    {oilRequest.description && (
                      <>
                        <div className="oil-request-page__title">
                          Kirjeldus:
                        </div>
                        <div>{oilRequest.description}</div>
                      </>
                    )}
                  </div>
                  {/* <div>Kütused:</div> */}
                  <div className="oil-request-page__oils">
                    <Oils oils={oilRequest.oils} />
                  </div>
                </div>
                {oilRequest.winner && (
                  <div className="col-sm-12">
                    <h5 className="oil-request-page__heading">Hanke võitja</h5>
                    <hr />
                    <div className="oil-request-page__info">
                      <div className="oil-request-page__title">Ettevõte:</div>
                      <div>{oilRequest.winner.company.name}</div>
                      <div className="oil-request-page__title">Telefon:</div>
                      <div>
                        {oilRequest.winner.company.contactPhone
                          ? oilRequest.winner.company.contactPhone
                          : "–"}
                      </div>
                      <div className="oil-request-page__title">
                        E-posti aadress:
                      </div>
                      <div>
                        {oilRequest.winner.company.contactEmail
                          ? oilRequest.winner.company.contactEmail
                          : "–"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>

            {Service.canCreateOrEditBid(user, oilRequest) && (
              <CreateOrEditBid
                user={user}
                oilRequest={oilRequest}
                oilRequestBids={oilRequestBids}
                updateOilRequest={this.getOilRequest}
              />
            )}

            <section>
              <h5 className="oil-request-page__heading">Pakkumised</h5>
              <hr />
              <OilRequestBidTable 
                oilRequest={oilRequest} 
                oilRequestBids={oilRequestBids} 
                onWinner={this.handleOilRequestWinner}
              />
            </section>

            {Service.canCreateQuestion(user, oilRequest) && (
              <section>
                <h5 className="oil-request-page__heading">Lisa küsimus</h5>
                <hr />

                <div>
                  <form onSubmit={this.createRequestQuestion}>
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Group className="mb-2">
                          <Form.Label className="mb-0 d-flex">
                            Küsimus
                          </Form.Label>
                          <Form.Text muted>
                            Küsimust näevad pakkujad ja ka hankija.{" "}
                          </Form.Text>
                          <Form.Control
                            as="textarea"
                            value={question}
                            name="question"
                            onChange={this.handleChange}
                            className="form-control"
                            isInvalid={Boolean(questionErrors.question)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {questionErrors.question}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div>
                      <button className="btn btn-warning">
                        Lisa küsimus
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            )}

            <section>
              <h5 className="oil-request-page__heading">Küsimused</h5>
              <hr />
              <div>{this.getQuestions()}</div>
            </section>
          </>
        )}
        <div className="row">
          <div className="col">
          <Link to="/oil-requests">
            <a href="/oil-requests" className="btn btn-light mb-5">
              Tagasi
            </a>
          </Link>
          </div>

          <div className="col-auto">
            <StampOilRequestAsChoosingWinner oilRequest={oilRequest} onSuccess={this.getOilRequest} />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showToast: (toast: any) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OilRequestPage));
