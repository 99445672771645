export const UserRole = Object.freeze({
  Bidder: "Bidder",
  Requester: "Requester",
  Agent: "Agent",
});

export const OilRequestStatus = Object.freeze({
  Decision: "Decision",
  Active: "Active",
  Closed: "Closed",
  Transport: "Transport",
});

export const OilRequestTransportCode = Object.freeze({
  Agreement: "Agreement",
  Requester: "Requester",
  Bidder: "Bidder",
});

export const OilRequestOilUsageType = Object.freeze({
  Fishing: "Fishing",
  Agriculture: "Agriculture",
  Wholesale: "Wholesale",
});

export const OilRequestOilType = Object.freeze({
  Petrol95: "Petrol95",
  Petrol98: "Petrol98",
  Diesel: "Diesel",
  DieselSpecial: "DieselSpecial",
  HydrotreatedVegetableOil: "HydrotreatedVegetableOil",
});

export const OilRequestOrder = Object.freeze({
  OilTotalAmountAsc: "OilTotalAmountAsc",
  OilTotalAmountDesc: "OilTotalAmountDesc",
  OilRequestEndingAsc: "OilRequestEndingAsc",
  OilRequestRegisteredDesc: "OilRequestRegisteredDesc",
});
